@font-face {
  font-family: 'anek';
  src: url('../fonts/anek.ttf') format('woff'),
  url('../fonts/anek.ttf') format('truetype');
}

@font-face {
  font-family: 'vazir';
  src: url('../fonts/ereg.woff2') format('woff'),
  url('../fonts/ereg.woff2') format('truetype');
}

@font-face {
  font-family: 'vazir';
  font-weight: bold;
  src: url('../fonts/ebold.woff2') format('woff'),
  url('../fonts/ebold.woff2') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  box-sizing:border-box;
  font: normal 14px 'vazir';
  line-height: 14px;
  text-decoration: none;
  outline: none;
  direction: rtl;
}

html {
  height: 100%;
  background: #F9FAFF;
}

html, body, #root {
  /* height: 100%; */
}

body::before {
  content: "";
  background: url('../images/pattern.png') repeat center / 100px;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.01;
  z-index: -1;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.container {
  width: calc(100% - 20px);
  min-width: 320px;
  max-width: 900px;
  margin: 0 auto;
}

.containerAuto {
  height: 100%;
}

.MuiCollapse-root {
  z-index: 100000;
  position: fixed;
  top: 12px;
}

.MuiDrawer-root > .MuiPaper-root {
  background: #F9FAFF !important;
}

.MuiAlert-message {
  padding: 11px 8px 3px !important;
}

.topMenu {
  /* height: 220px; */
  padding: 40px 0 160px;
  background: #2860E6 url('../images/bg.jpg') no-repeat center / cover;
  display: flex;
}

.mainUp {
  margin-top: -140px;
}

.topMenu .container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 15px;
}

.logo {
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  /* flex: 1; */
  /* margin-top: 30px; */
}

.logo span {
  background: url('../images/logo.svg') no-repeat center / contain;
  width: 32px;
  height: 32px;
}

.logo h1 {
  font-size: 20px;
  color: #fff;
  line-height: 28px;
  margin-top: -9px;
  font-weight: bold;
}

.logo h1 b {
  display: block;
  font-size: 11px;
  color: #eee;
  line-height: 11px;
  letter-spacing: -0.1px;
}

.menu {
  background: url('../images/menu.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.profile {
  background: #fff;
  border-radius: 8px;
  color: #111;
  padding: 13px 22px 15px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  /* margin-top: 30px; */
}

.form {
  border-radius: 8px;
  margin-top: 20px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 420px;
  overflow-y: scroll;
}

.text {
  text-align: center;
  color: #999;
  margin-top: 20px;
  font-size: 13px;
}

.inForm {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.inFormLarge {
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.inputParent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.inputParent input, .inputParent select, .selectBox {
  background: #fff;
  border: 1px solid #ddd;
  color: #000;
  border-radius: 8px;
  padding: 11px 18px 10px;
  direction: ltr;
  text-align: left;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.inFormTest .inputParent {
  flex-direction: row;
  align-items: center;
}

.selectBox p {
  padding: 5px 0;
}

.selectBox {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.selectBox > div {
  left: -1px;
  top: 45px;
  width: calc(100% + 2px);
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  position: absolute;
  padding: 8px 0;
  display: none;
}

.selectBox:hover > div {
  display: block;
}

.selectBox > img {
  width: 22px;
  height: 22px;
}

.selectBox > div i {
  cursor: pointer;
  padding: 7px 15px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 4px;
}

.selectBox > div img {
  width: 22px;
}

.inputParentSelect {
  position: relative;
}

.inputParentSelect::after {
  display: block;
  content: '';
  background: url('../images/back.svg') no-repeat center / contain;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 17px;
  top: 17px;
  transform: rotate(-90deg);
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
  opacity: 0.3;
}

.inputParent span {
  /* background: #232323; */
  /* width: auto; */
  /* text-align: center; */
  color: #333;
  /* border-radius: 100px; */
  font-size: 13px;
  /* line-height: 12px; */
  padding: 0 5px 7px;
  font-weight: bold;
  /* margin: -8px 0 0 10px; */
  /* text-transform: uppercase; */
}

.feeData {
  flex: 0.5;
}

.settingLiner {
  display: flex;
  margin: 0 1px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  background: #ddd;
  border-radius: 5px;
  width: 18px;
  height: 18px;
}

.settingLiner i {
  display: block;
  color: #fff;
  line-height: 12px;
  margin-top: 2px;
}

.checkboxActive {
  background: #079ae3;
}

.checkboxActive::after {
  display: block;
  content: '';
  background: url('../images/tick.svg') no-repeat center / contain;
  width: 100%;
  height: 100%;
}

.mainButton {
  background: #2860E6;
  color: #fff;
  padding: 15px 35px 16px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  justify-content: center;
  /* width: 100%; */
}

.mainButton::after {
  /* content: '';
  display: block;
  background: url('../images/back.svg') no-repeat center / contain;
  width: 16px;
  height: 16px; */
}

.feeData {
  background: #ccc;
}

.goLogin {
  text-align: center;
  cursor: pointer;
  color: #666;
  display: block;
  padding: 15px 0;
}

.popup h3 {
  font-size: 22px;
  color: #333;
  padding: 40px 40px 20px;
}

.publicCopy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7px 7px 30px;
  color: #fff;
  cursor: pointer;
}

.publicCopy img {
  border-radius: 4px;
}

.backParent {
  padding: 40px 40px 0;
  cursor: pointer;
}

.backIcon {
  display: block;
  content: '';
  background: url('../images/back.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
  opacity: 0.5;
  transform: rotate(180deg);
}

.balance, .empty {
  color: #ddd;
}

.empty {
  text-align: center;
  margin-top: 5px;
}

.logged {
  background: #079ae3;
  width: 13px;
  height: 13px;
  display: block;
  border-radius: 100px;
  padding: 1px;
  margin-top: -2px;
}

.logged::after {
  display: block;
  content: '';
  background: url('../images/tick.svg') no-repeat center / contain;
  width: 100%;
  height: 100%;
}

.tabs {
  display: flex;
  margin: -30px -30px 10px;
}

.tabsOver {
  display: flex;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
}

.tabsOver span {
  color: #444e67;
  cursor: pointer;
  padding: 18px 0;
  flex: 1;
  text-align: center;
  background: #f3f4f9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.tabs span {
  color: #444e67;
  cursor: pointer;
  padding: 18px 0 20px;
  flex: 1;
  text-align: center;
  background: #f3f4f9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.tabs span::before {
  content: '';
  /* background: url('../images/send.svg') no-repeat center / contain; */

  background: #576179;
  mask-image: url('../images/send.svg');
  -webkit-mask-image: url('../images/send.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;

  width: 22px;
  height: 22px;
}

.tabs span:nth-of-type(2):before {
  transform: rotate(180deg);
}

.tabs span.inActiveTabs, .tabsOver span.inActiveTabs {
  background: #fff;
  color: #2860E6;
  position: relative;
  /* font-weight: bold; */
}

.tabsOver .inActiveTabs::before {
  content: '';
  background: #2860E6;
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 100px;
  position: absolute;
  bottom: 7px;
}

.tabs span.inActiveTabs::before {
  background: #2860E6;
}

.showOrder {
  display: flex;
  flex-direction: column;
  color: #ddd;
  border-bottom: 1px solid #222;
  padding-bottom: 20px;
}

.showOrder span {
  font-size: 14px;
}

.showOrder:nth-last-of-type(1) {
  border: none;
  padding: 0;
}

.bottom {
  padding: 10px 0 30px;
}

.support {
  background: #222;
  width: 50px;
  height: 50px;
  position: fixed;
  left: 30px;
  bottom: 30px;
  border-radius: 100px;
  animation: example 1s linear infinite;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.support::after {
  display: block;
  content: '';
  background: url('../images/support.svg') no-repeat center / 34px;
  width: 100%;
  height: 100%;
}

.inFormLeft {
  justify-content: end;
  /* align-items: center; */
}

.rate {
  flex: 1;
  margin: 5px 0;
}

.ratePop {
  flex: 1;
}

.rate i {
  display: inline-block;
  font-weight: bold;
}

.infoDetail {
  background: #f3f4f9;
  font-size: 11px;
  flex: 1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.ratePop .rate:nth-of-type(2) {
  /* font-size: 12px; */
  margin-top: 10px;
}

.inputParentLarge {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.inputParentLarge > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: #f3f4f9;
  flex: 1;
  border-radius: 100px;
  padding: 5px 5px 5px 14px;
  border: 2px solid #f3f4f9;
  cursor: pointer;
}

.inputParentLarge > div.active {
  border-color: #2860E6;
  background: #fff;
}

.inputParentLarge > div img {
  width: 32px;
  height: 32px;
}

.inputParentLarge > div i {
  font-size: 13px;  
}

.inputParentLarge > div > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputParentLarge > div i:nth-of-type(2) {
  flex: 1;
  font-size: 11px;
  margin-top: 3px;
  /* text-align: left; */
}

.noItem {
  padding: 40px 0;
  text-align: center;
  /* direction: ltr; */
}

.rightPlace::placeholder {
  text-align: right; 
  direction: rtl;
}

.rightPlace::-webkit-input-placeholder {
  text-align: right; 
  direction: rtl;
}

.rightPlace:-moz-placeholder { /* Firefox 18- */
  text-align: right; 
  direction: rtl;
}

.rightPlace::-moz-placeholder {  /* Firefox 19+ */
  text-align: right; 
  direction: rtl;
}

.rightPlace:-ms-input-placeholder {  
  text-align: right; 
  direction: rtl;
}

.wrongData {
  text-align: center;
  background: #fd3213;
  color: #fff;
  padding: 18px 0 20px;
  border-radius: 100px;
}

.getData {
  text-align: center;
}

.getData b {
  display: inline-block;
  direction: ltr;
  font-weight: bold;
  color: #079ae3;
}

@keyframes example {
  from {
    transform: scale(1);
    background: #079ae3;
  }
  to {
    transform: scale(1.1);
  }
}

@media all and (max-width: 900px) {
  .inputParentLarge > div {
    flex: calc(100% / 4);
  }
}

@media all and (max-width: 600px) {
  .inputParent {
    width: 100%;
    flex: none;
  }
  .inputParentLarge > div {
    flex: calc(100% / 3);
  }
}

@media all and (max-width: 480px) {
  .mainButton {
    width: 100%;
    flex: none;
  }
}

@media all and (max-width: 410px) {
  .inputParentLarge > div {
    flex: calc(100% / 2);
  }
}
